const call = ({ url, method = 'GET', body = null, onSuccess, onError, }) => {
    fetch(
        url,
        {
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            ...(body ? { body: JSON.stringify(body) } : {} ),
        },
    )
        .then(response => response.json())
        .then(response => {
            if (response.error) {
                onError(response.error);
            } else {
                onSuccess(response.session);
            }
        })
        .catch((e) => {
            onError({
                type: 'NETWORK_ERROR',
                message: e.toString(),
            });
        })
};

export const getSession = ({
   url,
   id,
   token,
   onSuccess,
   onError,
}) => {
    call({
        url: `${url}/sessions/get/${id}?token=${token}`,
        method: 'GET',
        onSuccess,
        onError,
    });
}